import React from 'react'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { createThemeInstance } from '@contexts/ThemeProvider'
import { useTranslation } from 'next-i18next'
import { Cairo, Lexend_Deca as LexendDeca } from '@next/font/google'
import localFont from '@next/font/local'

const cairo = Cairo({
  subsets: ['arabic'],
  variable: '--cairo-font',
})

const SukhumvitSet = localFont({
  variable: '--sukhumvit-font',
  src: [
    {
      path: './fonts/SukhumvitSet-Thin.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: './fonts/SukhumvitSet-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/SukhumvitSet-Text.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/SukhumvitSet-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/SukhumvitSet-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/SukhumvitSet-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
})

const lexendDeca = LexendDeca({
  subsets: ['latin'],
  weight: ['300', '400', '600', '700', '800'],
  variable: '--lexend-deca-font',
})

const languagesFont = { en: SukhumvitSet.style, ar: cairo.style }
function MyThemeProvider({ children }) {
  const { i18n } = useTranslation()
  const direction = i18n?.dir?.(i18n.language) ?? 'ltr'
  const cache = createCache({
    key: `mui-${direction}`,
    stylisPlugins: [...(direction === 'rtl' ? [prefixer, rtlPlugin] : [])],
  })
  React.useEffect(() => {
    document.documentElement.dir = direction
  }, [direction])
  return (
    <ThemeProvider
      theme={createThemeInstance({
        direction,
        typography: {
          ...(languagesFont[i18n.language] ?? languagesFont.en),
        },
      })}
    >
      <CssBaseline />
      <CacheProvider value={cache}>
        <GlobalStyles
          styles={{
            ':root': {
              '--cairo-font': cairo.style.fontFamily,
              '--sukhumvit-font': SukhumvitSet.style.fontFamily,
              '--lexend-deca-font': lexendDeca.style.fontFamily,
            },
          }}
        />
        {children}
      </CacheProvider>
    </ThemeProvider>
  )
}

export default MyThemeProvider
