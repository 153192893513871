// import React from 'react'
// import { setLocale } from 'yup'
import * as yup from 'yup'
// import enLocale from 'yup/lib/locale'
// import * as yupLocales from 'yup-locales'
// import { useTranslation } from 'next-i18next'

yup.addMethod(yup.string, 'subdomain', function pattern(name, message = 'domain not valid') {
  return this.test({
    name,
    message,
    test: (value) =>
      value === null ||
      value === '' ||
      value === undefined ||
      value.match(/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/),
  })
})

function YupProvider({ children }) {
  // const { i18n } = useTranslation()
  // React.useEffect(() => {
  //   setLocale(yupLocales?.[i18n.language] ?? enLocale)
  // }, [i18n.language])
  return children
}

export default YupProvider
