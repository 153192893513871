import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { merge } from 'lodash'

function createThemeInstance(theme = {}) {
  return responsiveFontSizes(
    createTheme(
      merge(
        {
          palette: {
            primary: {
              main: '#3664a8',
            },
            secondary: {
              main: '#e3740a',
            },
            neutral: {
              dark: '#5A5A5A',
              main: '#F3F5F7',
              contrastText: '#fff',
            },
            store: {
              dark: '#222',
              main: '#000',
              contrastText: '#fff',
            },
            paper: {
              dark: '#DDD',
              main: '#FFF',
              contrastText: '#3664a8',
            },
          },
          typography: {
            fontFamily: 'var(--sukhumvit-font)',
            h1: { fontSize: 60, fontWeight: 700, lineHeight: 1 },
            h2: { fontSize: 50, fontWeight: 700, lineHeight: 1.4 },
            h3: { fontSize: 50, fontWeight: 700, lineHeight: 1.25 },
            h4: { fontSize: 32, fontWeight: 600, lineHeight: 1.25 },
            h5: { fontSize: 25, fontWeight: 600, lineHeight: 1.28 },
            h6: { fontSize: 20, fontWeight: 600, lineHeight: 1.25 },
            subtitle1: { fontSize: 20, fontWeight: 500, lineHeight: 1.5 },
            subtitle2: { fontSize: 18, fontWeight: 500, lineHeight: 1.4 },
            body1: { fontSize: 20, fontWeight: 500, lineHeight: 1.4 },
            body2: { fontSize: 18, fontWeight: 500, lineHeight: 1.3 },
            button: {
              fontWeight: 600,
              lineHeight: 1.75,
              // fontFamily: 'var(--cairo-font)',
              textTransform: 'capitalize',
            },
            caption: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
            overline: { fontSize: 16, fontWeight: 400, lineHeight: 1.8 },
          },
          components: {
            MuiSvgIcon: {
              styleOverrides: {
                root: {
                  'html[dir=rtl] &': {
                    transform: 'scaleX(-1)',
                  },
                },
              },
            },
            MuiRadio: {
              styleOverrides: {
                root: {
                  '& .MuiSvgIcon-root:last-child': {
                    transform: 'scaleX(0)',
                  },
                  '&.Mui-checked': {
                    '& .MuiSvgIcon-root:last-child': {
                      transform: 'scaleX(1)',
                    },
                  },
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: 10,
                },
                notchedOutline: {
                  border: 0,
                },
              },
            },
            MuiTypography: {
              defaultProps: {
                variantMapping: {
                  h1: 'h2',
                  h2: 'h2',
                  h3: 'h2',
                  h4: 'h2',
                  h5: 'h2',
                  h6: 'h2',
                  subtitle1: 'h2',
                  subtitle2: 'h2',
                  body1: 'span',
                  body2: 'span',
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: '8px',
                  paddingLeft: 24,
                  paddingRight: 24,
                },
                sizeSmall: {
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 18,
                  paddingRight: 18,
                  fontSize: 16,
                },
                sizeMedium: {
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 22,
                  paddingRight: 22,
                  fontSize: 18,
                },
                sizeLarge: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 26,
                  paddingRight: 26,
                  fontSize: 20,
                },
              },
            },
          },
        },
        theme
      )
    )
  )
}

export default createThemeInstance
