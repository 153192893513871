import React from 'react'
import ThemeProvider from '@contexts/ThemeProvider'
import { appWithTranslation } from 'next-i18next'
import NextNProgress from 'nextjs-progressbar'
import { useTheme } from '@mui/material'
import ScrollToTop from '@components/ScrollToTop'
import { ToastContainer } from 'react-toastify'
import QueryProvider from '@contexts/QueryProvider'
import YupProvider from '@contexts/YupProvider'
import 'moment/locale/ar'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/styles.css'

function MyApp({ Component, pageProps }) {
  const theme = useTheme()
  return (
    <ThemeProvider>
      <YupProvider>
        <NextNProgress
          color={theme.palette.primary.main}
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={false}
          options={{ showSpinner: false }}
        />
        <QueryProvider pageProps={pageProps}>
          <ScrollToTop />
          <ToastContainer />
          <Component {...pageProps} />
        </QueryProvider>
      </YupProvider>
    </ThemeProvider>
  )
}

export default appWithTranslation(MyApp)
