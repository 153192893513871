import React from 'react'
import { useRouter } from 'next/router'

function ScrollToTop() {
  const { pathname } = useRouter()
  const [mounted, setMounted] = React.useState(false)
  React.useEffect(() => {
    // don't run scroll after first load
    if (mounted) window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])
  React.useEffect(() => {
    setMounted(true)
  }, [])
  return null
}

export default ScrollToTop
